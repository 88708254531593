<template>
    <div>
        <div class="row justify-items-end">
           <div class="col">
                <b-form-group label="Filter" :label-cols-sm="3" label-align-sm="end" label-size="sm" label-for="filterInput" class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input v-model="filter" :debounce="700" type="text" id="filterInput" placeholder="Type to Search"></b-form-input>
                        <b-button v-if="filter" @click="filter = null" variant="outline-dark" size="sm">⨯</b-button>
                    </b-input-group>
                </b-form-group>
            </div>
        </div>

        <b-table id="action-stats-table" hover borderless striped responsive
            v-model:sort-by="sortBy"
            v-model:sort-desc="sortDesc"

            :items="tableData"
            :fields="tableFields"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"

            @filtered="onFiltered"
        >
            <template v-slot:cell(metadata)="{value}">
                <ActionMetadata :data="value" />
            </template>

            <template v-slot:cell(created)="{value}">
                <span class="text-nowrap">{{ $filters.datetime(value) }}</span>
            </template>

            <template v-slot:cell()="{value}">
                {{ value }}
            </template>

        </b-table>

        <div class="d-flex align-items-center">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="action-stats-table"
                class="mb-0"
            ></b-pagination>

            <label for="perPageSelect" class="ms-4 mb-0 text-nowrap small">Per page</label>
            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions" class="ms-2 w-auto"></b-form-select>
        </div>
    </div>
</template>

<script>
import ActionMetadata from '@/components/applog/ActionMetadata.vue'

export default {
    props: {
        data: { type: Array }
    },
    data: function() {
        return {
            perPage: 10,
            currentPage: 1,
            sortBy: "created",
            sortDesc: true,
            filter: null,
            totalRows: 0
        }
    },
    components: {
        ActionMetadata
    },
    computed: {
        tableFields: function() {
            return [
                {
                    key: 'id',
                    sortable: true,
                    label: "id"
                },
                {
                    key: 'name',
                    sortable: false,
                    label: "jméno",
                    tdClass: "text-nowrap"
                },
                {
                    key: 'email',
                    sortable: false,
                    label: "email",
                    tdClass: "text-nowrap"
                },
                {
                    key: 'type',
                    sortable: true,
                    label: 'typ'
                },
                {
                    key: 'created',
                    sortable: true,
                    label: 'čas vytvoření',
                    tdClass: "text-nowrap"
                },
                {
                    key: 'metadata',
                    sortable: false,
                    label: "metadata",
                    tdClass: "text-nowrap"
                }
            ]
        },
        tableData: function() {
            if (this.data == null) {
                return []
            }

            var result = []
            this.data.forEach(n => {
                result.push({
                    "id": n.id,
                    "email": n.email,
                    "name": n.name,
                    "type": n.type,
                    "created": n.created,
                    "metadata": n.metadata != null ? JSON.parse(n.metadata) : null
                })
            })

            return result
        },
        pageOptions: function() {
            return [10, 20, 50]
        },
        filterOn: function() {
            return ["email", "type", "metadata"]
        }
    },
    mounted() {
        this.totalRows = this.tableData?.length || 0
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems?.length || 0
            this.currentPage = 1
        }
    }
}
</script>

<style lang="scss" scoped>

</style>